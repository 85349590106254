

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../components/Whiteboard/Whiteboard'
import Whiteboard from '../../components/Whiteboard/Whiteboard'
import AddNotes from '../pages/AddNotes'

export default function HomePage() {


  var timer;
  const handleClick = async (event) => {

    clearTimeout(timer);

    if (event.detail === 1) {

      timer = setTimeout(async () => {



        let GoogleLogin = localStorage.getItem("GLogin");

        let NormalLogin = localStorage.getItem("NLogin");

        console.log("GoogleLogin", typeof GoogleLogin, GoogleLogin)

        console.log("NormalLogin", typeof NormalLogin, NormalLogin)



        if (GoogleLogin === "1") {

          let data;
          let UserAdress;

          await fetch("https://api.ipify.org?format=json")
            .then(response => response.json())
            .then((responseJson) => {
              data = responseJson
              console.log("Response of IP ======>", responseJson)
            }).catch((error) => {
              console.log("error", error)
            })
          if(data!=null)
          {
          await fetch(`https://ipapi.co/${data.ip}/json/`)
            .then(response => response.json())
            .then((responseJson) => {
              UserAdress = responseJson;
              console.log("response of adresss:=====>", responseJson)
            }).catch((error) => {
              console.log("error", error)
            })



          let GoogleDetails = JSON.parse(localStorage.getItem("GoogleDetails"))

          console.log("GoogleDetails", typeof GoogleDetails, GoogleDetails)


          await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/GmailUserLoginStatus", {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              FullName: GoogleDetails.name,
              Email: GoogleDetails.email,
              City: UserAdress.city,
              CountryName: UserAdress.country_name,
              CountryCode: UserAdress.country_code,
              country_capital: UserAdress.country_capital,
              PostalCode: UserAdress.postal,
              region: UserAdress.region,
              region_code: UserAdress.region_code

            })
          })
        }
        }
        if (NormalLogin === "1") {
          let userID = localStorage.getItem("UserId");
          let RoleID = localStorage.getItem("RoleId");
          let name = localStorage.getItem("Name");
          console.log("normal login", userID, RoleID, name)

          let data;
          let UserAdress;

          await fetch("https://api.ipify.org?format=json")
            .then(response => response.json())
            .then((responseJson) => {
              data = responseJson
              console.log("Response of IP ======>", responseJson)
            }).catch((error) => {
              console.log("error", error)
            })
          if(data!=null){
          await fetch(`https://ipapi.co/${data.ip}/json/`)
            .then(response => response.json())
            .then((responseJson) => {
              UserAdress = responseJson;
              console.log("response of adresss:=====>", responseJson)
            }).catch((error) => {
              console.log("error", error)
            })

          let result = await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/NormalUserLoginStatus", {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              FullName: name,
              UserId: userID,
              RoleID: RoleID,
              City: UserAdress.City,
              CountryName: UserAdress.CountryName,
              CountryCode: UserAdress.CountryCode,
              country_capital: UserAdress.country_capital,
              PostalCode: UserAdress.PostalCode,
              region: UserAdress.region,
              region_code: UserAdress.region_code

            })
          })
          console.log("result", result)
        }
        }
      }, 200)
    } else if (event.detail === 2) {

      let GoogleLogin = localStorage.getItem("GLogin");
      let NormalLogin = localStorage.getItem("NLogin");
      console.log("GoogleLogin", typeof GoogleLogin, GoogleLogin)
      console.log("NormalLogin", typeof NormalLogin, NormalLogin)
      if (GoogleLogin === "1") {

        let GoogleDetails = JSON.parse(localStorage.getItem("GoogleDetails"))
        console.log("GoogleDetails", typeof GoogleDetails, GoogleDetails)

        let data;
        let UserAdress;

        await fetch("https://api.ipify.org?format=json")
          .then(response => response.json())
          .then((responseJson) => {
            data = responseJson
            console.log("Response of IP ======>", responseJson)
          }).catch((error) => {
            console.log("error", error)
          })
        if(data!=null){
        await fetch(`https://ipapi.co/${data.ip}/json/`)
          .then(response => response.json())
          .then((responseJson) => {
            UserAdress = responseJson;
            console.log("response of adresss:=====>", responseJson)
          }).catch((error) => {
            console.log("error", error)
          })


        await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/GmailUserLoginStatus", {
          method: 'post',

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            FullName: GoogleDetails.name,
            Email: GoogleDetails.email,
            City: UserAdress.City,
            CountryName: UserAdress.CountryName,
            CountryCode: UserAdress.CountryCode,
            country_capital: UserAdress.country_capital,
            PostalCode: UserAdress.PostalCode,
            region: UserAdress.region,
            region_code: UserAdress.region_code

          })
        })
      }
      }
      if (NormalLogin === "1") {
        let userID = localStorage.getItem("UserId");
        let RoleID = localStorage.getItem("RoleId");
        let name = localStorage.getItem("Name");
        console.log("normal login", userID, RoleID, name)

        let data;
        let UserAdress;

        await fetch("https://api.ipify.org?format=json")
          .then(response => response.json())
          .then((responseJson) => {
            data = responseJson
            console.log("Response of IP ======>", responseJson)
          }).catch((error) => {
            console.log("error", error)
          })
          if(data!=null)
          {
        await fetch(`https://ipapi.co/${data.ip}/json/`)
          .then(response => response.json())
          .then((responseJson) => {
            UserAdress = responseJson;
            console.log("response of adresss:=====>", responseJson)
          }).catch((error) => {
            console.log("error", error)
          })


        let result = await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/NormalUserLoginStatus", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            FullName: name,
            UserId: userID,
            RoleID: RoleID,
            City: UserAdress.City,
            CountryName: UserAdress.CountryName,
            CountryCode: UserAdress.CountryCode,
            country_capital: UserAdress.country_capital,
            PostalCode: UserAdress.PostalCode,
            region: UserAdress.region,
            region_code: UserAdress.region_code

          })
        })
        //      console.log("result", result)
      }
      }
      //console.log("DOUBLE CLICK");

    }
  }
  return (

    // daniel.rothfus@dynapt.com - 2024-10-18 - I am modifying the below line
    // from <div onClick={handleClick}> to <div> because the user information
    // collection is failing and resulting in "Unhandled Rejection (TypeError):
    // _UserAdress is undefined" errors. This disables that collection
    // alltogether.
    <div>
      {/* {console.log("userAdress==============>:",UserAdress)} */}
      {/* // <nav>
        //     <span><h1>navbar</h1></span>
        //         <ul className='list-unstyled'>
        //             <li><h4>edit profile</h4></li>
        //         </ul>
        //     </nav> */}
      {/* <div className="text-center">
            <h1 className="main-title home-page-title">welcome to Learn-up</h1>
            <Link to="/">
                <button className="primary-button">Log out</button>
            </Link>
        </div> */}
      {/* // <div className="App"></div> */}

      <Whiteboard />
      {/* <AddNotes/> */}
    </div>
  )
}

